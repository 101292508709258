import React from "react";
import { Link } from "gatsby";

import Logo from "../assets/images/logo_sm.png";

const Nav = () => (
  <div>
    <nav
      className="navbar navbar-default navbar-top"
      role="navigation"
      style={{ zIndex: "999999999" }}
    >
      <div className="menuWrapper">
        <div className="container">
          <div className="navbar-header">
            <button
              id="menu-toggle"
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              <span className="sr-only">Navi</span>
              <span className="icon-bar bar1"></span>
              <span className="icon-bar bar2"></span>
              <span className="icon-bar bar3"></span>
            </button>
            <Link to="/">
              <div className="logo-container">
                <div className="logo">
                  <img
                    src={Logo}
                    alt="kidsup - system zarządzania przedszkolem i żłobkiem dla zaawansowanych, aplikacja do pilnowania dzieci"
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav">
              <li>
                <Link to={`/funkcje`}>Funkcje</Link>
              </li>
              <li>
                <Link to={`/opinie`}>Opinie</Link>
              </li>
              <li>
                <Link to={`/kontakt`}>Kontakt</Link>
              </li>
            </ul>

            <ul className="nav navbar-nav navbar-right">
              <li>
                <a className="menu-right-part" href="https://system.kidsup.pl">
                  Logowanie
                </a>
              </li>
              <li>
                <Link className="btn violet-btn" to={`/zamow`}>
                  Wypróbuj
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
);

export default Nav;
