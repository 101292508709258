import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import "../assets/css/landing-page.css";
import { helmetJsonLdProp } from "react-schemaorg";

if (typeof window !== "undefined") {
  window.jQuery = window.$ = require("jquery");
  require("bootstrap");
  // eslint-disable-next-line no-unused-expressions
  import("../assets/js/scripts");
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "UA-158706876-1");
}

const breadcrumb = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  description:
    " Kidsup to wygodny i intuicyjny system do zaawansowanego zarządzania i komunikacji między przedszkolem lub żłobkiem a rodzicami. Sprawdź!",
  name: "Nastap Tech S.A.",
  telephone: "+48 885 444 990",
  email: "kontakt@kidsup.pl",
  url: "https://kidsup.pl",
  image:
    "https://www.kidsup.pl/static/MacBookPro-2-0ad1289b19f1c25f48419eb942181329.png",
  sameAs: [
    "https://www.facebook.com/kidsuppl/",
    "https://www.instagram.com/kidsup.pl/",
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Lelewela 6A",
    addressLocality: "Poznań",
    postalCode: "61-409",
    addressCountry: "PL",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 52.65541529999999,
    longitude: 16.8032881,
  },
};

const SEO = ({ seo = {}, title, noRobots }) => {
  // defaultSeo siteName favicon

  // Merge default and page-specific SEO values

  const defaultSeo = {
    metaTitle: "kidsup - system obsługi przedszkoli i żłobków",
    metaDescription:
      "Kidsup to wygodny i intuicyjny system do zaawansowanego zarządzania i komunikacji między przedszkolem lub żłobkiem a rodzicami. Sprawdź!",
    shareImage: {
      publicURL:
        "/static/6121479bef69cf3815b81f1c066a730b/61fab4c8cafc5fba924c6f8d65111343.jpeg",
    },
  };

  const fullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.publicURL;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    if (noRobots) {
      tags.push({
        property: "robots",
        content: "noindex",
      });
    }

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | kidsup - system obsługi przedszkoli i żłobków`}
      link={[
        {
          rel: "icon",
          href:
            "/static/00465929186c783b2e9cdbd6f794451d/a5faa7895d100215ec13698949736537.png",
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Staatliches",
        },
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css?family=Ubuntu:regular,bold,medium,light&display=swap",
        },
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css?family=Open+Sans:300italic,400,300",
        },
        {
          rel: "stylesheet",
          href:
            "https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css",
        },
        {
          rel: "stylesheet",
          href:
            "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/css/uikit.min.css",
        },
      ]}
      meta={metaTags}
      bodyAttributes={{ class: "landing-page landing-page1" }}
    >
      <html lang="pl" />

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-158706876-1"
      ></script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
