import React from "react";
import { Link } from "gatsby";

import LogoHeart from "../assets/images/logo_heart.png";
import IconPhone from "../assets/images/icon_phone.png";
import IconMail from "../assets/images/icon_email.png";
import IconPlace from "../assets/images/icon_map.png";
import AppStore from "../assets/images/app_store.png";
import GooglePlay from "../assets/images/googlePlay.png";
const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <div className="footer-logo-wrapper">
            <img src={LogoHeart} alt="Logo" className="footer-icon-download" />
          </div>
        </div>
        <div className="col-md-3 col-xs-6">

          <div className="footer-part">
            <i>
              <img className="footer-icon" src={IconMail} alt="email" />
            </i>
            <a href="mailto:biuro@kidsup.pl" className="footer-element">
              kontakt@kidsup.pl
            </a>
          </div>
          <div className="footer-part">
            <i>
              <img
                className="footer-icon"
                src={IconPlace}
                alt="lokalizacja"
                style={{ padding: "8px 10px" }}
              />
            </i>
            <p className="footer-element">
              Nastap Tech S.A. <br /> ul. Lelewela 6A, 61-409 Poznań
            </p>
          </div>
        </div>
        <div className="col-md-3 col-xs-6">
          <p className="footer-title">Ważne</p>
          <Link className="footer-link" to={"/prywatnosc"}>
            Polityka prywatności
          </Link>
          <Link className="footer-link" to={"/regulamin"}>
            Regulamin dla Użytkowników
          </Link>
        </div>
        <div className="col-md-3 col-xs-12">
          <div
            className="btns"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <a href="https://itunes.apple.com/us/app/kidsup/id1451646951?l=pl&ls=1&mt=8">
              <img
                className="footer-icon-download"
                src={AppStore}
                alt="App Store"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=pl.kidsup.kidsup&hl=pl">
              <img
                className="footer-icon-download"
                src={GooglePlay}
                alt="Google Play"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
