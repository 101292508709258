import React from "react";
import PropTypes from "prop-types";
import Nav from "./nav";
import Footer from "./footer";
import Seo from "./seo";
import TagManager from "react-gtm-module";
import CookieConsent from "react-cookie-consent";

const tagManagerArgs = {
  gtmId: "GTM-W278RCF",
  dataLayerName: "PageDataLayer",
};
if (typeof window !== "undefined") {
  TagManager.initialize(tagManagerArgs);
}

const Layout = ({ children, seo, title, noRobots }) => (
  <>
    <Seo seo={seo} title={title} noRobots={noRobots} />
    <Nav />
    <main>{children}</main>
    <Footer />
    <CookieConsent
      location="bottom"
      buttonText="Rozumiem"
      cookieName="kidsupMain"
      buttonStyle={{
        background: "#3C086A",
        borderRadius: "10px",
        color: "white",
        fontWeight: "500",
        fontFamily: "Ubuntu",
        fontSize: "14px",
        margin: "auto",
        marginTop: "40px",
        border: "none",
        padding: "13px 20px",
      }}
      disableStyles={true}
      expires={150}
    >
      Klikając <span style={{ fontWeight: "500" }}>„Rozumiem”</span>, zgadzasz
      się na przechowywanie plików cookie na swoim urządzeniu w celu
      usprawnienia nawigacji na stronie, analizy i pomocy w naszych działaniach
      marketingowych.
    </CookieConsent>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
